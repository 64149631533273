.highlighter {
  position: relative;
  width: 100%;
  height: 100%;
}
.text-container {
  padding: 20px;
}
.inline-toolbar {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
