.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .text-green-600 {
  color: #109f3b !important;
}

.text-gray-600 {
  color: #415a77 !important;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header .ant-menu-overflow {
  border: 0 !important;
}

.header .ant-btn-link {
  background-color: #c9dfff8f;
}

.header .ant-btn {
  margin: 0 0.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pdf-modal .ant-modal {
  top: 20px; 
  height: 90vh;
}

.option-button {
  background-color: #c9dfff8f;
  color: #415a77;
  padding: 0.06125rem 0.75rem !important;
  height: unset !important;
}

.option-button:hover {
  background-color: #c9dfff5a !important;
  color: #1b263b !important;
  border: 1px solid #033f9a8f !important;
}

.option-button i {
  margin-right: 0.5rem;
  color: #e07a5f;
}

.info-tag {
  background-color: #e0e1dd;
  border: none;
  color: #415a77;
  i {
    margin-right: 0.25rem;
    color: #415a77;
  }
}

/* .gpt-chip {
  background-color: #e0e1dd50;
  border: none;
  color: #415a77;
  padding: 0.125rem 0.5rem;
  border-radius: 8px;

  .label {
    margin-right: 0.25rem;
    color: #415a7798;
  }
} */

.gpt-chip {
  background-color: #e0e1dd;
  border: none;
  color: #415a77;
  padding: 0.0125rem 0.5rem;
  border-radius: 6px;
  font-size: 12px !important;
  line-height: 20px;
  .label {
    margin-right: 0.25rem;
    color: #566577;
  }
}

.pubmed-title {
  color: #415a77 !important;
}

.info-tag-top {
  background-color: transparent;
  border: 1px solid #778da9;
  color: #415a77;
  border-radius: 4px;

  
}

.info-tag-top.include {
  background-color: #c5fad5;
  color: #109f3b;
  border: none;
}

.info-tag-top.exclude {
  background-color: #fae0d8;
  color: #fd4e1d;
  border: none;
}

.summary-item {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: top;

  .label {
    font-weight: bold;
    margin-right: 0.5rem;
    display: block;
    width: 100px;

  }

  p{ 
    flex: auto;
    margin: 0;

  }
}

.ant-collapse-header {
  padding: 12px 8px !important;
}

/* .ant-popover-arrow {
  clip-path:  path('M 8 0 L 16 8 L 8 16 L 0 8 Z') !important;
} */

.ant-popover-placement-top .ant-popover-arrow {
  clip-path:  path('M 8 0 L 16 8 L 8 16 L 0 8 Z') !important;
  transform: translateX(-50%) translateY(50%) rotate(0deg) !important;
}

.ant-popover-placement-bottom .ant-popover-arrow {
  clip-path:  path('M 8 0 L 16 8 L 8 16 L 0 8 Z') !important;
  transform: translateX(-50%) translateY(-50%) rotate(0deg) !important;
}

.ant-tabs-content {
  height: 100%;
}

.react-json-view {
  * {
    font-size: 13px !important;
  }

  .string-value {
    line-height: 1.15 !important;
  }
}
